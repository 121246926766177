.search-bar {
  /* height: 20%; */
  padding-left: 15%;
  padding-right: 15%;

}

.search-bar .frame {
  margin-top: 40px;
  /* margin-bottom: 20px; */

  display: inline-flex;
  width: 100%;
  border: 1px solid black;

}

.search-bar .search {

}

.search-bar .input {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 20px;
  border: none;
  outline: none;
}