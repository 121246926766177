.book-details {
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.book-details .data-and-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.book-details .book-data {
  padding-left: 20px;
  padding-top: 35px;
  display: flex;
  flex-direction: row;
}

.book-details .book-actions {
  padding-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.book-details .back-link {
  font-size: 1.5rem;
}

.book-details img {
  padding-right: 10px;
}

.book-details .time-period {
  display: flex;
  flex-direction: row;
}

.book-tile {
  width: 150px;
  height: 150px;
  background-color: #94D2BD;
  margin-left: 10px;
  margin-right: 10px;
}

.book-details .download-excel {
  cursor: pointer;
}