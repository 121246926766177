@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');


body {
  font-family: "Inconsolata", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
}