@import "https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap";
a {
  color: #000;
  text-decoration: none;
}

a:hover {
  border-bottom: 3px solid #94d2bd;
}

.header {
  border-bottom: 1px solid #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
}

.logo {
  font-size: 32px;
}

.links {
  font-size: 20px;
}

.links > a, .links > span {
  margin-left: 10px;
  margin-right: 10px;
}

.search-bar {
  padding-left: 15%;
  padding-right: 15%;
}

.search-bar .frame {
  border: 1px solid #000;
  width: 100%;
  margin-top: 40px;
  display: inline-flex;
}

.search-bar .input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 20px;
}

.landing-page {
  height: 100%;
}

.book-tiles {
  flex-wrap: wrap;
  padding-top: 40px;
  display: flex;
}

.book-details {
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.book-details .data-and-actions {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.book-details .book-data {
  flex-direction: row;
  padding-top: 35px;
  padding-left: 20px;
  display: flex;
}

.book-details .book-actions {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 35px;
  display: flex;
}

.book-details .back-link {
  font-size: 1.5rem;
}

.book-details img {
  padding-right: 10px;
}

.book-details .time-period {
  flex-direction: row;
  display: flex;
}

.book-tile {
  background-color: #94d2bd;
  width: 150px;
  height: 150px;
  margin-left: 10px;
  margin-right: 10px;
}

.book-details .download-excel {
  cursor: pointer;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #00f;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --rdp-selected-color: #fff;
  margin: 1em;
}

.rdp-vhidden {
  box-sizing: border-box;
  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.rdp-button_reset {
  appearance: none;
  cursor: default;
  color: inherit;
  font: inherit;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.rdp-button_reset:focus-visible {
  outline: none;
}

.rdp-button {
  border: 2px solid #0000;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: .25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: var(--rdp-background-color);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  margin: 0;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
}

.rdp-multiple_months .rdp-caption {
  text-align: center;
  display: block;
  position: relative;
}

.rdp-caption_dropdowns {
  display: inline-flex;
  position: relative;
}

.rdp-caption_label {
  z-index: 1;
  white-space: nowrap;
  color: currentColor;
  font-family: inherit;
  font-size: var(--rdp-caption-font-size);
  border: 2px solid #0000;
  align-items: center;
  margin: 0;
  padding: 0 .25em;
  font-weight: bold;
  display: inline-flex;
  position: relative;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: .25em;
  display: inline-flex;
}

.rdp-dropdown_year, .rdp-dropdown_month {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-dropdown {
  appearance: none;
  z-index: 2;
  cursor: inherit;
  opacity: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #0000;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row, .rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-align: center;
  height: var(--rdp-cell-size);
  text-transform: uppercase;
  height: 100%;
  padding: 0;
  font-size: .75em;
  font-weight: 700;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: .5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  text-align: center;
  height: 100%;
  padding: 0;
}

.rdp-weeknumber {
  font-size: .75em;
}

.rdp-weeknumber, .rdp-day {
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  border: 2px solid #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  color: var(--rdp-selected-color);
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

.rdp-day_outside {
  opacity: .5;
}

.rdp-day_selected:focus-visible {
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start), .rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

body {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Inconsolata, monospace;
  font-style: normal;
  font-weight: 400;
}

.app {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.container {
  flex-direction: column;
  flex: 1;
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}
/*# sourceMappingURL=index.694cd0b0.css.map */
