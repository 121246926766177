.landing-page {
  /* background-color: blueviolet; */
  height: 100%;
}

.book-tiles {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
}

.book-tile {
  width: 150px;
  height: 150px;
  background-color: #94D2BD;
  margin-left: 10px;
  margin-right: 10px;
}