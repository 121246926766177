a {
  text-decoration: none;
  color: black;
}

a:hover {
  border-bottom: 3px solid #94D2BD;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.logo {
  font-size: 32;
}

.links {
  font-size: 20;
}

.links > a {
  margin-left: 10px;
  margin-right: 10px;
}

.links > span {
  margin-left: 10px;
  margin-right: 10px;
}